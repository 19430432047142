import React, { useState, useEffect, useMemo } from 'react';
import styles from './ConsumptionAnalysis.module.scss';
import moment from 'moment';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import ConsumptionAnalysisActions from '../../../actions/consumptionAnalysisActions';
import UserStore from '../../../stores/userStore';

import { Table, Select, Row, Col, Card, Typography } from 'antd';

import { DocumentTitle } from '../../../components/DocumentTitle';
import { LogoSpinner } from '../../../components/LogoSpinner';
import { TimeframePicker } from '../../../components/TimeframePicker';
import { Icon } from '../../../components/Icon';
import { ShareButton } from '../../../components/ShareButton';

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import GeneralUtils from '../../../utils/GeneralUtils';

const { Title, Text, Link } = Typography;

const ConsumptionAnalysis = () => {
    const { token } = useParams();
    const [isLoggedIn] = useState(UserStore.loggedIn());

    const [consumptionData, setConsumptionData] = useState(null);
    const [analysisInfo, setAnalysisInfo] = useState(null);
    const [selectedMeter, setSelectedMeter] = useState(null);
    const [availableMeters, setAvailableMeters] = useState([]);
    const [timeframe, setTimeframe] = useState({
        start: moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'week').startOf('isoWeek').add(6, 'days').format('YYYY-MM-DD'),
        granularity: 'week'
    });
    const [loading, setLoading] = useState(false);
    const [metersLoading, setMetersLoading] = useState(false);

    useEffect(() => {
        const fetchConsumptionData = async () => {
            setLoading(true);
            try {
                const data = await ConsumptionAnalysisActions.getConsumptionData(selectedMeter, timeframe.start, moment(timeframe.end).add(1, 'day').format('YYYY-MM-DD'));
                setConsumptionData(data.consumption_analysis);
            } catch (error) {
                setConsumptionData(null);
                console.error('Failed to fetch data:', error);
            } finally {
                setLoading(false);
            }
        };

        if (timeframe.start && timeframe.end && selectedMeter && isLoggedIn && !token) fetchConsumptionData();

    }, [timeframe.start, timeframe.end, selectedMeter, isLoggedIn, token]);

    useEffect(() => {
        const fetchMeters = async () => {
            setMetersLoading(true);
            try {
                const data = await ConsumptionAnalysisActions.getAvailableMeters(timeframe.start, moment(timeframe.end).add(1, 'day').format('YYYY-MM-DD'));
                setAvailableMeters(data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            } finally {
                setMetersLoading(false);
            }
        };

        if (timeframe.start && timeframe.end && isLoggedIn && !token) fetchMeters()

    }, [timeframe.start, timeframe.end, isLoggedIn, token]);

    useEffect(() => {
        const fetchDataViaToken = async () => {
            setLoading(true);
            try {
                const data = await ConsumptionAnalysisActions.getAnalysisByToken(token);
                setConsumptionData(data.consumption_analysis);
                setAnalysisInfo({
                    meter: data.meter,
                    building: data.building,
                    search_interval: data.search_interval
                });
            } catch (error) {
                console.error('Failed to fetch configuration via token:', error);
                setConsumptionData({});
            } finally {
                setLoading(false);
            }
        };

        if (token) fetchDataViaToken();
    }, [token]);

    const getColors = (value = null) => {
        const good = '#168000';
        const moderate = '#ff9f00';
        const poor = '#d04b4b';
        const worst = '#b84343';

        if (!value) {
            return {
                good,
                moderate,
                poor,
                worst
            }
        } else {
            if (value <= 10) return good;
            if (value <= 20) return moderate;
            return poor;
        }
    }

    const Section = ({ title, children }) => {
        return <Card className={styles.section} bordered={false} styles={{
            body: {
                padding: 15
            }
        }}>
            <div className={styles.cardTitle}>{title || ''}</div>
            <div>
                {children}
            </div>
        </Card>
    }

    const getPieOptions = (insideConsumption, outsideConsumption) => {
        return {
            chart: {
                type: 'pie',
                backgroundColor: 'transparent',
                height: 120,
                spacing: [0, 0, 0, 0],
                marginTop: 0,
                marginRight: 0,
                marginBottom: 0,
                marginLeft: 0,
            },
            title: {
                text: null
            },
            tooltip: {
                enabled: true,
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b><br>Value: <b>{point.y:.1f}</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: false,
                    cursor: 'default',
                    showInLegend: false,
                    innerSize: '75%',
                    size: '100%',
                    colors: ['#55aef8', '#5253be'],
                    dataLabels: {
                        enabled: true,
                        distance: 0,
                        format: '{point.name}<br>{point.percentage:.0f}%',
                        style: {
                            fontSize: '10px',
                            color: 'black',
                            textAlign: 'center'
                        }
                    }
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Consumption',
                data: [
                    {
                        name: 'Inside',
                        y: insideConsumption,
                        tooltip: `Inside Hours: <b>${insideConsumption && insideConsumption.toFixed(1)}</b>`
                    },
                    {
                        name: 'Outside',
                        y: outsideConsumption,
                        tooltip: `Outside Hours: <b>${outsideConsumption && outsideConsumption.toFixed(1)}</b>`
                    }
                ]
            }],
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            }
        }
    }

    // Meter Selectors
    const getMeterSelector = () => {

        return <div className={styles.meterPickerSection}>
            <Select
                options={availableMeters}
                style={{
                    width: '100%',
                    marginTop: '10px'
                }}
                filterSort={(optionA, optionB) => optionA.label.localeCompare(optionB.label)}
                value={selectedMeter}
                onChange={selectMeterHandler}
                placeholder={`Select the Meter`}
                showSearch
                size={'large'}
                allowClear
                onClear={() => selectMeterHandler(null)}
                filterOption={(input, option) =>
                    (_.get(option, 'label') || '').toLowerCase().includes(input.toLowerCase())
                }
            />
        </div>;

    };

    const selectMeterHandler = (meter) => {
        setSelectedMeter(meter);
    }

    // Timeframe picker
    const getTimeframePicker = () => {
        return <div className={styles.timeframePickerSection}>
            <TimeframePicker
                start={timeframe.start}
                end={timeframe.end}
                onChange={setTimestamps}
                granularities={['week', 'month', 'year']}
                defaultGranularity={timeframe.granularity}
                spacing="10px"
                backgroundColor='#fff'
                buttonColor='#5e656e'
                disableToday
            />
        </div>;

    };

    const setTimestamps = (start, end, granularity) => {
        const ts_start = moment(start).format('YYYY-MM-DD');
        let ts_end = moment(end).format('YYYY-MM-DD');

        if (moment(ts_end).isAfter(moment(), 'day')) {
            ts_end = moment().format('YYYY-MM-DD');
        }

        setTimeframe({
            start: ts_start,
            end: ts_end,
            granularity: granularity
        });
    }

    // OOHP
    const getOOHPerformanceBar = () => {
        let value = _.get(consumptionData, 'outside_opening_hours_performance.overall');
        const colors = getColors();
        if (value) value = value.toFixed(0);
        const position = calculateIndicatorPosition(value);

        return (
            <div className={styles.oohpSectionBar}>
                <div style={{ background: !!value && `linear-gradient(to right, ${colors.good} 0%, ${colors.moderate} 20%, ${colors.poor} 30%, ${colors.worst} 100%)` }} className={styles.bar}>
                    {!!value && <div className={styles.thresholdIndicator} style={{ left: '10%' }}>
                    </div>}
                    {!!value && <div className={styles.thresholdIndicator} style={{ left: '20%' }}>
                    </div>}
                    {!!value && <div className={styles.valueIndicator} style={{ left: `${position}%` }}>
                        <div className={styles.topLabel}>
                            <div className={styles.valueNumber}>{value}</div>
                            <Icon name='ArrowDown' color='black' size={50} />
                        </div>
                    </div>}
                    {!!value && <div className={styles.goodLabel} style={{ left: '0%' }}>
                        Good
                    </div>}
                    {!!value && <div className={styles.poorLabel} style={{ left: '95%' }}>
                        Poor
                    </div>}
                </div>
                <div className={styles.explanation}>
                    {/* Thresholds are based on performance for similar buildings. */}
                </div>
            </div>
        );
    };

    const getOOHPerformanceValue = () => {
        let value = _.get(consumptionData, 'outside_opening_hours_performance.overall');
        const colors = getColors();
        let bgColor = 'white'
        let valColor = 'black'
        let grade = '-'

        if (value) {
            value = value.toFixed(2);
            bgColor = GeneralUtils.getColorFromGradient([
                { position: 0, color: colors.good },
                { position: 20, color: colors.moderate },
                { position: 30, color: colors.poor },
                { position: 100, color: colors.worst }
            ], value);
            valColor = GeneralUtils.getContrastColor(bgColor);
            grade = value <= 10 ? 'Good' : value <= 20 ? 'Average' : 'Poor';
        }

        return (
            <div className={styles.oohpSectionValue}>
                <div className={styles.valueLabel} style={{
                    backgroundColor: bgColor,
                    color: valColor
                }}>
                    {grade || '-'}
                </div>
                <div className={styles.explanation}>
                    The ratio of consumption per hour outside opening hours to consumption per hour inside opening hours.
                </div>
            </div>
        );
    };

    const calculateIndicatorPosition = (value) => {
        if (value === null || value === undefined) return 0;
        return Math.min(Math.max(value, 0), 98);
    };

    // Energy Consumption Pie
    const getEnergyConsumptionPie = useMemo(() => {
        const insideConsumption = _.get(consumptionData, 'inside_opening_hours.total_consumption');
        const outsideConsumption = _.get(consumptionData, 'outside_opening_hours.total_consumption');

        if (insideConsumption === 0 && outsideConsumption === 0) {
            return <div className={styles.noDataMessage}>No data available</div>;
        }

        const chartOptions = getPieOptions(insideConsumption, outsideConsumption);

        return (
            <div className={styles.energyConsumptionPieSection} style={{ opacity: loading || metersLoading ? 0 : 1 }}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                />
            </div>
        );
    }, [consumptionData, loading, metersLoading]);

    // Consumption Hours Pie
    const getConsumptionHoursPie = useMemo(() => {
        const insideConsumption = _.get(consumptionData, 'inside_opening_hours.total_hours');
        const outsideConsumption = _.get(consumptionData, 'outside_opening_hours.total_hours');

        if (insideConsumption === 0 && outsideConsumption === 0) {
            return <div className={styles.noDataMessage}>No data available</div>;
        }

        const chartOptions = getPieOptions(insideConsumption, outsideConsumption);

        return (
            <div className={styles.consumptionHoursPieSection} style={{ opacity: loading || metersLoading ? 0 : 1 }}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                />
            </div>
        );
    }, [consumptionData, loading, metersLoading]);

    // Table
    const getTableContent = () => {
        const columns = [
            { title: 'Time Period', dataIndex: 'timePeriod', key: 'timePeriod' },
            {
                title: 'Hours',
                dataIndex: 'hours',
                key: 'hours',
                render: (text) => text !== null && text !== undefined ? text.toFixed(1) : '-'
            },
            {
                title: 'Energy (kWh)',
                dataIndex: 'consumption',
                key: 'consumption',
                render: (text) => text !== null && text !== undefined ? text.toFixed(0) : '-'
            },
            {
                title: 'Emissions (kgCO2e)',
                dataIndex: 'emissions',
                key: 'emissions',
                render: (text) => text !== null && text !== undefined ? text.toFixed(0) : '-'
            },
        ];

        const dataSource = [
            {
                key: '1',
                timePeriod: 'Inside Hours',
                hours: _.get(consumptionData, 'inside_opening_hours.total_hours'),
                consumption: _.get(consumptionData, 'inside_opening_hours.total_consumption'),
                emissions: _.get(consumptionData, 'inside_opening_hours.total_emissions'),
            },
            {
                key: '2',
                timePeriod: 'Outside Hours',
                hours: _.get(consumptionData, 'outside_opening_hours.total_hours'),
                consumption: _.get(consumptionData, 'outside_opening_hours.total_consumption'),
                emissions: _.get(consumptionData, 'outside_opening_hours.total_emissions'),
            },
            {
                key: '3',
                timePeriod: 'Total',
                hours: addNonNull(
                    _.get(consumptionData, 'inside_opening_hours.total_hours'),
                    _.get(consumptionData, 'outside_opening_hours.total_hours')
                ),
                consumption: addNonNull(
                    _.get(consumptionData, 'inside_opening_hours.total_consumption'),
                    _.get(consumptionData, 'outside_opening_hours.total_consumption')
                ),
                emissions: addNonNull(
                    _.get(consumptionData, 'inside_opening_hours.total_emissions'),
                    _.get(consumptionData, 'outside_opening_hours.total_emissions')
                ),
            },
        ];

        return (
            <div className={styles.tableSection}>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    bordered={false}
                    size="small"
                    className={styles.customTable}
                    rowClassName={styles.customRow}
                />
            </div>
        );
    };

    const addNonNull = (...args) => {
        if (args.some(arg => arg === null || arg === undefined)) {
            return null;
        }
        return args.reduce((sum, arg) => sum + arg, 0);
    };

    // Grouped Bar Chart
    const getGroupedBarChart = useMemo(() => {
        const colors = getColors();

        const dailyPerformance = _.get(consumptionData, 'outside_opening_hours_performance.weekly_performance', []);

        const chartData = dailyPerformance.map(day => {
            let color;
            let grade;
            if (day.value <= 10) {
                color = colors.good;
                grade = 'Good';
            } else if (day.value <= 20) {
                color = colors.moderate;
                grade = 'Average';
            } else {
                color = colors.poor;
                grade = 'Poor';
            }
            return {
                x: moment(day.date).valueOf(),
                y: day.value,
                color: color,
                grade: grade
            };
        });

        const chartOptions = {
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
                height: 300,
            },
            title: {
                text: null
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    format: '{value:%b %d}',
                    rotation: -45,
                    align: 'right'
                }
            },
            yAxis: {
                title: {
                    text: 'OOHP KPI (%)'
                },
            },
            tooltip: {
                headerFormat: '<b>Week starting {point.x:%A, %b %d, %Y}</b><br/>',
                pointFormat: 'Grade: {point.grade}<br/>OOHP: {point.y:.2f}'
            },
            series: [{
                name: 'OOHP',
                data: chartData,
                colorByPoint: true
            }],
            plotOptions: {
                column: {
                    pointPadding: 0.1,
                    borderWidth: 0,
                    groupPadding: 0.05,
                    shadow: false
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            }
        };

        return (
            <div className={styles.chartContainer} style={{ opacity: loading || metersLoading ? 0 : 1 }}>
                <HighchartsReact
                    containerProps={{
                        style: { height: '100%', width: '100%' }
                    }}
                    highcharts={Highcharts}
                    options={chartOptions}
                />
            </div>
        );
    }, [consumptionData, loading, metersLoading]);

    const getObjectForToken = () => {
        return {
            type: "CONSUMPTION_ANALYSIS",
            meter_id: selectedMeter,
            search_interval: {
                start: moment(timeframe.start).format('YYYY-MM-DD'),
                end: moment(timeframe.end).add(1, 'day').format('YYYY-MM-DD')
            }
        };
    }

    // Shared Version

    if (token) {
        const meter = _.get(analysisInfo, 'meter.description', 'Unknown Meter')
        const building = _.get(analysisInfo, 'building.name', 'Unknown Building')
        const start = _.get(analysisInfo, 'search_interval.start');
        const end = _.get(analysisInfo, 'search_interval.end');
        const timeframe = `${moment(start).format('DD MMM YYYY')} - ${moment(end).subtract(1, 'days').format('DD MMM YYYY')}`

        const title = `Out-of-Hours Energy Analysis for ${meter} at ${building}`

        return <div
            className={`${styles.consumptionAnalysis} br-mainpanel ${!isLoggedIn && styles.outOfLogin} br-profile-page floorplan-background`}
        >
            <DocumentTitle title="Out-of-Hours Energy Analysis" />
            <LogoSpinner loading={loading || metersLoading} />
            <div className='br-container'>
                <div className={styles.mainWrapper}>
                    <Row gutter={[20, 20]} justify={'center'} className={styles.mainRow}>
                        <Col xs={24} sm={20}>
                            <Title className={styles.mainTitle} level={3}>{title}</Title>
                            <Title className={styles.timeframe} level={5}>{timeframe}</Title>
                            {isLoggedIn && (
                                <Text type='secondary'>
                                    This is a shared report, to select new meter/timeframes for out-of-hours energy analysis, {' '}
                                    <Link href="/analysis/consumption-analysis">click here</Link>.
                                </Text>
                            )}
                        </Col>
                        <Col xs={24} sm={4}>
                            <ShareButton
                                condition={selectedMeter && timeframe.start && timeframe.end}
                                object={getObjectForToken()}
                                generateToken={ConsumptionAnalysisActions.generateToken}
                                urlPath="analysis/consumption-analysis/{newToken}"
                            />
                        </Col>
                        <Col span={12} xs={24} md={12} style={{ display: 'none' }}>
                            <Section title={'Meter Selection'}>
                                {getMeterSelector()}
                            </Section>
                        </Col>
                        <Col span={12} xs={24} md={12} style={{ display: 'none' }}>
                            <Section>
                                {getTimeframePicker()}
                            </Section>
                        </Col>
                        <Col xs={24} md={8}>
                            <Section title={'OOHP Grade'} tooltip="The ratio of consumption per hour outside opening hours to consumption per hour inside opening hours.">
                                {getOOHPerformanceValue()}
                            </Section>
                        </Col>
                        <Col xs={24} md={16}>
                            <Section title={'Out-of-Hours Performance (OOHP)'}>
                                {getOOHPerformanceBar()}
                            </Section>
                        </Col>
                        <Col span={24}>
                            <Text className={styles.sectionTitle} level={5}>Consumption Details</Text>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Section title={'Energy Consumption'}>
                                {getEnergyConsumptionPie}
                            </Section>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Section title={'Consumption Hours'}>
                                {getConsumptionHoursPie}
                            </Section>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Section>
                                {getTableContent()}
                            </Section>
                        </Col>
                        <Col span={24} style={{ display: timeframe.granularity === 'week' && 'none' }}>
                            <Text className={styles.sectionTitle} level={5}>Out-of-Hours Performance (OOHP) per Week</Text>
                        </Col>
                        <Col span={24} order={20} style={{ display: timeframe.granularity === 'week' && 'none' }}>
                            <Section className={styles.chartSection}>
                                {getGroupedBarChart}
                            </Section>
                        </Col>
                    </Row>
                </div>
            </div>
        </div >
    }

    // Non-shared version before selections
    if (!consumptionData) {
        return <div
            className={`${styles.consumptionAnalysis} br-mainpanel ${!isLoggedIn && styles.outOfLogin} br-profile-page floorplan-background`}
        >
            <DocumentTitle title="Out-of-Hours Energy Analysis" />
            <LogoSpinner loading={loading} />
            <div className='br-container'>
                <div className={styles.mainWrapper}>
                    <Row gutter={[20, 20]} justify={'center'} className={styles.mainRow}>
                        <Col span={24}>
                            <Title className={styles.mainTitle} level={3}>Out-of-Hours Energy Analysis</Title>
                        </Col>
                        <Col span={12} xs={24} xl={12} xxl={12}>
                            <Section title={'Meter Selection'}>
                                {getMeterSelector()}
                            </Section>
                        </Col>
                        <Col span={12} xs={24} xl={12} xxl={12}>
                            <Section>
                                {getTimeframePicker()}
                            </Section>
                        </Col>
                    </Row>
                </div>
            </div>
        </div >
    }

    return (
        <div
            className={`${styles.consumptionAnalysis} br-mainpanel ${!isLoggedIn && styles.outOfLogin} br-profile-page floorplan-background`}
        >
            <DocumentTitle title="Out-of-Hours Energy Analysis" />
            <LogoSpinner loading={loading} />
            <div className='br-container'>
                <div className={styles.mainWrapper}>
                    <Row gutter={[20, 20]} justify={'center'} className={styles.mainRow}>
                        <Col xs={24} sm={20}>
                            <Title className={styles.mainTitle} level={3}>Out-of-Hours Energy Analysis</Title>
                        </Col>
                        <Col xs={24} sm={4}>
                            <ShareButton
                                condition={selectedMeter && timeframe.start && timeframe.end}
                                object={getObjectForToken()}
                                generateToken={ConsumptionAnalysisActions.generateToken}
                                urlPath="analysis/consumption-analysis/{newToken}"
                            />
                        </Col>
                        <Col span={24} xs={12} >
                            <Section title={'Meter Selection'}>
                                {getMeterSelector()}
                            </Section>
                        </Col>
                        <Col span={24} xs={12}>
                            <Section>
                                {getTimeframePicker()}
                            </Section>
                        </Col>
                        <Col xs={24} md={8}>
                            <Section title={'OOHP Grade'} tooltip="The ratio of consumption per hour outside opening hours to consumption per hour inside opening hours.">
                                {getOOHPerformanceValue()}
                            </Section>
                        </Col>
                        <Col xs={24} md={16}>
                            <Section title={'Out-of-Hours Performance (OOHP)'}>
                                {getOOHPerformanceBar()}
                            </Section>
                        </Col>
                        <Col span={24}>
                            <Text className={styles.sectionTitle} level={5}>Consumption Details</Text>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Section title={'Energy Consumption'}>
                                {getEnergyConsumptionPie}
                            </Section>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Section title={'Consumption Hours'}>
                                {getConsumptionHoursPie}
                            </Section>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Section>
                                {getTableContent()}
                            </Section>
                        </Col>
                        <Col span={24} style={{ display: timeframe.granularity === 'week' && 'none' }}>
                            <Text className={styles.sectionTitle} level={5}>Out-of-Hours Performance (OOHP) per Week</Text>
                        </Col>
                        <Col span={24} order={20} style={{ display: timeframe.granularity === 'week' && 'none' }}>
                            <Section className={styles.chartSection}>
                                {getGroupedBarChart}
                            </Section>
                        </Col>
                    </Row>
                </div>
            </div>
        </div >
    );
};

export default ConsumptionAnalysis;
