import React, { useRef, useState, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import HC_more from "highcharts/highcharts-more";
import styles from './DataExplorerChart.module.scss';
import _ from 'lodash';
import moment from 'moment';

import { Icon } from '../../../../components/Icon';
import refreshIcon from '../../../../assets/icons/refresh.png';

HC_exporting(Highcharts);
HC_export_data(Highcharts);
HC_more(Highcharts);

// Add Highcharts wrap to modify the 'post' method for exporting
Highcharts.wrap(Highcharts, 'post', function (proceed, url, data, formAttributes) {
    var frame;
    if (!Highcharts.exportFrame) {
        frame = document.createElement('iframe');
        frame.id = 'hc-export-frame';
        frame.style.display = 'none';
        document.body.appendChild(frame);
        Highcharts.exportFrame = frame;
    }
    formAttributes = Highcharts.merge({ target: 'hc-export-frame' }, formAttributes);
    proceed.call(this, url, data, formAttributes);
});

// Highcharts bandaid bugfix from https://github.com/highcharts/highcharts/issues/3967
Highcharts.Pointer.prototype.getHoverData = function (
    existingHoverPoint,
    existingHoverSeries,
    series,
    isDirectTouch,
    shared,
    e
) {
    var hoverPoint,
        hoverPoints = [],
        hoverSeries = existingHoverSeries,
        useExisting = !!(isDirectTouch && existingHoverPoint),
        notSticky = hoverSeries && !hoverSeries.stickyTracking,
        filter = function (s) {
            return (
                s.visible &&
                !(!shared && s.directTouch) && // #3821
                Highcharts.pick(s.options.enableMouseTracking, true)
            );
        },
        // Which series to look in for the hover point
        searchSeries = notSticky ?
            // Only search on hovered series if it has stickyTracking false
            [hoverSeries] :
            // Filter what series to look in.
            series.filter(function (s) {
                return filter(s) && s.stickyTracking;
            });

    // Use existing hovered point or find the one closest to coordinates.
    hoverPoint = useExisting || !e ?
        existingHoverPoint :
        this.findNearestKDPoint(searchSeries, shared, e);

    // Assign hover series
    hoverSeries = hoverPoint && hoverPoint.series;

    // If we have a hoverPoint, assign hoverPoints.
    if (hoverPoint) {
        // When tooltip is shared, it displays more than one point
        if (shared && !hoverSeries.noSharedTooltip) {
            searchSeries = series.filter(function (
                s
            ) {
                return filter(s) && !s.noSharedTooltip;
            });

            // Get all points with the same x value as the hoverPoint
            searchSeries.forEach(function (
                s
            ) {
                var point = Highcharts.find(s.points, function (
                    p
                ) {
                    return p.clientX === hoverPoint.clientX && !p.isNull;
                });

                if (Highcharts.isObject(point)) {
                    /*
                        * Boost returns a minimal point. Convert it to a usable
                        * point for tooltip and states.
                        */
                    if (s.chart.isBoosting) {
                        point = s.getPoint(point);
                    }
                    hoverPoints.push(point);
                }
            });
        } else {
            hoverPoints.push(hoverPoint);
        }
    }
    return {
        hoverPoint: hoverPoint,
        hoverSeries: hoverSeries,
        hoverPoints: hoverPoints
    };
};

const HIGHCHARTS_DATE_FORMAT = '%A, %b %e, %Y %H:%M';

const DataExplorerChart = ({ data, selectedProperties, onRowSelectionChange, cardOpen, loading, timeframeTitle, granularity, refreshData }) => {
    const chartRef = useRef(null);
    const [colors, setColors] = useState({});
    const [primaryAxisDataType, setPrimaryAxisDataType] = useState(null);

    useEffect(() => {
        const newColors = { ...colors };
        const chartSeries = _.get(chartRef, 'current.chart.series', []);
        const usedColors = new Set(chartSeries.map(series => series.color));
        // Slightly adjusted Highcharts colors
        const availableColors = [
            "#7cb5ec",
            "#434348",
            "#90ed7d",
            "#f7a35c",
            "#8085e9",
            "#FF95CA",
            "#e4d354",
            "#2b908f",
            "#f45b5b",
            "#91e8e1"
        ].filter(color => !usedColors.has(color));

        const selectedKeys = new Set(selectedProperties.map(p => `${p.property.property_id}-${p.entity.entity_type_id}`));

        Object.keys(newColors).forEach(key => {
            if (!selectedKeys.has(key)) {
                delete newColors[key];
            }
        });

        selectedProperties.forEach((p) => {
            const key = `${p.property.property_id}-${p.entity.entity_type_id}`;
            if (!newColors[key]) {
                if (availableColors.length > 0) {
                    newColors[key] = availableColors.shift();
                } else {
                    newColors[key] = Highcharts.getOptions().colors[Object.keys(newColors).length % Highcharts.getOptions().colors.length];
                }
            }
        });

        setColors(newColors);
        // eslint-disable-next-line
    }, [selectedProperties, chartRef.current]);

    useEffect(() => {
        // Cleanup function to remove iframe on unmount
        return () => {
            const iframe = document.getElementById('hc-export-frame');
            if (iframe) {
                iframe.parentNode.removeChild(iframe);
            }
        };
    }, []);

    const getYAxes = () => {
        const uniqueDataTypes = [...new Set(data.map(item => item.property.data_type))];

        if (!primaryAxisDataType || !uniqueDataTypes.includes(primaryAxisDataType)) {
            setPrimaryAxisDataType(uniqueDataTypes[0]);
        }

        return uniqueDataTypes.map((dataType, index) => {
            const unit = getUnitFromDataType(dataType).unit;
            const label = getUnitFromDataType(dataType).label;
            const isPrimary = dataType === primaryAxisDataType;

            return {
                title: {
                    text: label,
                },
                opposite: !isPrimary,
                labels: {
                    format: '{value}' + unit,
                }
            };
        });
    }

    const getUnitFromDataType = (dataType) => {
        if (dataType === "tempC") return { label: "Temperature", unit: "°C", decimals: 1 };
        if (dataType === "percent") return { label: "Percent", unit: "%", decimals: 2 };
        if (dataType === "kwh") return { label: "Kilowatt Hours", unit: " kWh", decimals: 2 };
        if (dataType === "kw") return { label: "Kilowatts", unit: " kW", decimals: 2 };
        if (dataType === "degDays") return { label: "Degree Days", unit: "", decimals: 2 };
        if (dataType === "kvarh") return { label: "Reactive Power", unit: " kVArh" };
        if (dataType === "pascals") return { label: "Pressure", unit: " Pa" };
        if (dataType === "ppm") return { label: "CO2", unit: " ppm", decimals: 0 };
        if (dataType === "level") return { label: "Level", unit: "" };
        if (dataType === "decimal") return { label: "Cost", unit: "" };
        if (dataType === "lux") return { label: "Illuminance", unit: ' lx' };
        if (dataType === "m3") return { label: "Volume", unit: ' m3' };
        if (dataType === "integer") return { label: "Count", unit: '' };
        if (dataType === "pf") return { label: "Power Factor", unit: ' PF' };
        if (dataType === "pulse") return { label: "Pulse", unit: '' };
        if (dataType === "ugm3") return { label: "Pollutant Concentration", unit: ' µg/m3' };

        return {
            label: dataType,
            unit: ''
        }
    }

    const getChartSeries = () => {
        let pointNames = [];
        let comparisonMode = false;
        let timestamps = [];
        let comparisonTimestamps = [];

        // Timestamp identifier that allows alignment of series in comparison mode
        const createTimestampIdentifier = (timestamp, index = null) => {
            if (granularity === 'DAY') return moment(timestamp).format('HHmm')
            if (granularity === 'WEEK') return moment(timestamp).format('EHHmm')
            if (granularity === 'MONTH') return moment(timestamp).format('DDHHmm')
            if (granularity === 'YEAR') return moment(timestamp).format('DDDHHmm')
            if (granularity === 'CUSTOM') return index + moment(timestamp).format('HHmm')
        }

        const series = data.map((item) => {
            const key = `${item.property.property_id}-${item.entity_type.entity_type_id}`;
            const color = colors[key];

            // Determine if the series is for the comparison timeframe
            const isComparison = pointNames.includes(key)
            if (isComparison) comparisonMode = true;
            pointNames.push(key);

            // List of dates for custom alignment, first digit being the index of the date of the timestamp
            const dates = [...new Set(item.data.map((dataItem) => moment(dataItem.timestamp).format('YYYY-MM-DD')))];

            const seriesData = item.data.map((dataItem) => {
                let uniqueIdentifier = createTimestampIdentifier(dataItem.timestamp);
                if (granularity === 'CUSTOM') {
                    const index = dates.indexOf(moment(dataItem.timestamp).format('YYYY-MM-DD'));
                    uniqueIdentifier = createTimestampIdentifier(dataItem.timestamp, index)
                }

                const valueOf = moment(dataItem.timestamp).valueOf();
                const obj = {
                    label: uniqueIdentifier,
                    x: moment(dataItem.timestamp).valueOf(),
                    y: dataItem.value,
                }

                if (isComparison) {
                    if (!comparisonTimestamps.some(e => e.label === obj.label)) {
                        comparisonTimestamps.push(obj)
                    }
                } else {
                    if (!timestamps.some(e => e.label === obj.label)) {
                        timestamps.push(obj)
                    }
                }

                return {
                    label: uniqueIdentifier,
                    x: valueOf,
                    y: dataItem.value,
                }

            });

            const label = getUnitFromDataType(item.property.data_type).label;
            const yAxisIndex = getYAxes().findIndex((yAxis) => yAxis.title.text === label);

            return {
                name: isComparison ? item.property.name + ' (comparison)' : item.property.name,
                building: item.building,
                entity: item.entity_type,
                data: seriesData,
                type: "spline",
                yAxis: yAxisIndex,
                xAxis: isComparison ? 1 : 0, // Use the second X axis for comparison
                color: color,
                isComparison: isComparison,
                dashStyle: isComparison ? 'ShortDot' : 'Solid', // Dashed line for comparison
                property_id: item.property.property_id,
                entity_type_id: item.entity_type.entity_type_id,
                tooltip: {
                    valueSuffix: getUnitFromDataType(item.property.data_type).unit,
                    valueDecimals: getUnitFromDataType(item.property.data_type).decimals,
                }
            };
        });

        //  xAxis alignment
        if (comparisonMode) {
            // Grabbing all comparison and noncomparison timestamps
            const sortedTimestamps = timestamps.map(t => ({ label: t.label, y: t.y, x: t.x }))
            const sortedComparisonTimestamps = comparisonTimestamps.map(t => ({ label: t.label, y: t.y, x: t.x }))

            // Finding differing elements between comp and noncomp series
            const differingElements = [..._.differenceBy(sortedTimestamps, sortedComparisonTimestamps, 'label'), ..._.differenceBy(sortedComparisonTimestamps, sortedTimestamps, 'label')];

            // cloning them
            let backFilledTimestamps = [...sortedTimestamps];
            let backFilledComparisonTimestamps = [...sortedComparisonTimestamps];

            // backfilling the missing elements with 'tbc' as timestamp (to be calculated)
            differingElements.forEach(element => {
                if (!backFilledTimestamps.some(e => e.label === element.label)) {
                    backFilledTimestamps.push({ label: element.label, y: null, x: 'tbc' })
                }

                if (!backFilledComparisonTimestamps.some(e => e.label === element.label)) {
                    backFilledComparisonTimestamps.push({ label: element.label, y: null, x: 'tbc' })
                }
            })

            // ordering
            backFilledTimestamps = _.orderBy(backFilledTimestamps, 'label');
            backFilledComparisonTimestamps = _.orderBy(backFilledComparisonTimestamps, 'label');

            // Creating diff gap that will help extrapolate ts, difference between aligned comp and noncomp points is constant
            let diffGap;
            sortedTimestamps.forEach((v, i) => {
                try {

                    const target = sortedComparisonTimestamps.find(e => e.label === v.label);
                    const diff = v.x - target.x;

                    if (diff) {
                        diffGap = diff;
                    }
                } catch {
                }
            })

            // taking all the 'tbc' values and backfilling them with the diff gap and filling them with opposite series (comp/noncomp)
            backFilledTimestamps = backFilledTimestamps.map((v) => {
                if (v.x === 'tbc') {
                    const correspondingData = backFilledComparisonTimestamps.find(e => e.label === v.label);
                    const x = correspondingData.x + diffGap

                    return {
                        ...v,
                        x,
                    }
                }
                return {
                    ...v,
                }
            })

            backFilledComparisonTimestamps = backFilledComparisonTimestamps.map((v) => {
                if (v.x === 'tbc') {
                    const correspondingData = backFilledTimestamps.find(e => e.label === v.label);
                    const x = correspondingData.x - diffGap
                    return {
                        ...v,
                        x,
                    }
                }
                return {
                    ...v
                }
            })

            // ordering chronologically
            backFilledTimestamps = _.orderBy(backFilledTimestamps, 'x');
            backFilledComparisonTimestamps = _.orderBy(backFilledComparisonTimestamps, 'x');

            // building series with aligned and backfilled timestamps
            const alignedSeries = series.map(s => {
                const srcArr = s.isComparison ? backFilledComparisonTimestamps : backFilledTimestamps;

                return {
                    ...s,
                    data: srcArr.map((src) => {
                        const target = s.data.find(e => e.label === src.label);

                        return {
                            ...target,
                            x: target ? target.x : src.x
                        }
                    }),

                }
            })

            return alignedSeries;

        }

        return series
    }

    const getChartOptions = () => {
        const series = getChartSeries();
        const yAxes = getYAxes();
        const hasComparison = series.some(series => series.isComparison);

        return {
            chart: {
                zoomType: "xy",
                height: 260,
            },
            title: {
                text: timeframeTitle,
                align: 'left',
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    fontFamily: 'Archivo Semibold, sans-serif',
                }
            },
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            xAxis: [
                {
                    gridLineDashStyle: "longdash",
                    type: "datetime",
                    gridLineWidth: 1,
                    gridLineColor: "#e6e6e6",
                    minorLineWidth: 1,
                    startOnTick: false,
                    endOnTick: false,
                    labels: {
                        style: {
                            color: "#343a40",
                            fontSize: "9px",
                        },
                    },
                    plotBands: [],
                },
                {
                    gridLineDashStyle: "longdash",
                    type: "datetime",
                    gridLineWidth: 1,
                    gridLineColor: "#e6e6e6",
                    minorLineWidth: 1,
                    startOnTick: false,
                    endOnTick: false,
                    labels: {
                        style: {
                            color: "#343a40",
                            fontSize: "9px",
                        },
                    },
                    plotBands: [],
                    opposite: true,
                }
            ],
            yAxis: yAxes,
            tooltip: {
                shared: true,
                useHTML: true,
                xDateFormat: HIGHCHARTS_DATE_FORMAT,
                formatter: hasComparison ? function () {
                    let tooltip = `<table style="border-spacing: 0 10px;"><thead><tr><th>Point</th>`;
                    const dates = this.points.map(point => Highcharts.dateFormat(HIGHCHARTS_DATE_FORMAT, point.x));
                    const uniqueDates = [...new Set(dates)];
                    uniqueDates.forEach(date => {
                        tooltip += `<th style="padding: 0 10px;">${date}</th>`;
                    });
                    tooltip += `</tr></thead><tbody>`;

                    const groupedPoints = {};
                    this.points.forEach(point => {
                        const key = point.series.name.replace(' (comparison)', '');
                        if (!groupedPoints[key]) {
                            groupedPoints[key] = [];
                        }
                        groupedPoints[key].push(point);
                    });

                    Object.keys(groupedPoints).forEach(key => {
                        const pointColor = groupedPoints[key][0].color;
                        tooltip += `<tr><td><span style="color:${pointColor}; font-size: 15px; margin-right: 5px;">\u25CF</span> <b>${key}</b></td>`;
                        uniqueDates.forEach(date => {
                            const point = groupedPoints[key].find(p => Highcharts.dateFormat(HIGHCHARTS_DATE_FORMAT, p.x) === date);
                            if (point) {
                                let value = point.y;
                                if (point.series.tooltipOptions.valueDecimals) value = value.toFixed(point.series.tooltipOptions.valueDecimals);
                                tooltip += `<td style="padding: 0 10px;">${value} ${point.series.tooltipOptions.valueSuffix}</td>`;
                            } else {
                                tooltip += `<td style="padding: 0 10px;">-</td>`;
                            }
                        });
                        tooltip += `</tr>`;
                    });

                    tooltip += `</tbody></table>`;
                    return tooltip;
                } : null
            },
            plotOptions: {
                series: {
                    turboThreshold: 0,
                    marker: {
                        enabled: false,
                    },
                    groupPadding: 0,
                    pointPadding: 0,
                    borderWidth: 0.2,
                    pointPlacement: "on",
                },
                line: {
                    dataLabels: {
                        enabled: false,
                    },
                    softThreshold: false,
                    series: {
                        marker: {
                            enabled: false,
                        },
                    },
                },
                column: {
                    softThreshold: false,
                },
                area: {
                    stacking: "normal",
                    step: "right",
                    softThreshold: false,
                },
            },
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        enabled: true,
                        align: 'right',
                        verticalAlign: 'top',
                        symbol: 'menu',
                        x: 0,
                        menuItems: [
                            "viewFullscreen",
                            "printChart",
                            "downloadPNG",
                            "downloadPDF",
                            "downloadSVG",
                            "downloadCSV",
                            "downloadXLS"]
                    },
                    customButton: {
                        symbol: `url(${refreshIcon})`,
                        symbolX: 20,
                        symbolY: 19,
                        symbolFill: '#666666',
                        onclick: function () {
                            refreshData();  // Call the refresh function
                        }
                    }
                },
                csv: {
                    columnHeaderFormatter: function (item) {
                        if (!item || item instanceof Highcharts.Axis) {
                            return 'Timestamp';
                        } else {
                            const property = _.get(item, 'userOptions.name', 'UNKNOWN PROPERTY')
                            const asset = _.get(item, 'userOptions.entity.name', 'UNKNOWN ASSET')
                            const building = _.get(item, 'userOptions.building.name', 'UNKNOWN BUILDING')
                            return `${property}, ${asset}, ${building}`
                        }
                    }
                }
            },
            series: series,
        };
    }

    const getChartLabels = () => {
        const chartSeries = _.get(chartRef, 'current.chart.series');

        return <div className={styles.labelWrapper}>
            {selectedProperties.map((p) => {
                const key = `${p.property.property_id}-${p.entity.entity_type_id}`;
                let color = '#eeeeee';
                let bgColor = '#eeeeee33';
                let noData;

                if (chartSeries) {
                    const serie = chartSeries.find((s) => s.userOptions.property_id === p.property.property_id && s.userOptions.entity_type_id === p.entity.entity_type_id);
                    if (serie) {
                        color = colors[key];
                        bgColor = color + '33';
                        noData = serie.data.length ? false : true;
                    } else {
                        noData = true;
                    }
                }

                const handleMouseEnter = () => {
                    chartSeries.forEach((s) => {
                        if (s.userOptions.property_id === p.property.property_id && s.userOptions.entity_type_id === p.entity.entity_type_id) {
                            s.setState('hover');
                        } else {
                            s.setState('inactive');
                        }
                    });
                };

                const handleMouseLeave = () => {
                    chartSeries.forEach((s) => {
                        s.setState('normal');
                    });
                };

                const matching_dataset = data.find((d) => d.property.property_id === p.property.property_id && d.entity_type.entity_type_id === p.entity.entity_type_id);
                const property = _.get(p, 'property.name', _.get(matching_dataset, 'property.name', '-'));
                const entity = _.get(p, 'entity.name', _.get(matching_dataset, 'entity_type.name', '-'));
                const building = _.get(p, 'building.name', _.get(matching_dataset, 'building.name', '-'));

                if (noData) {
                    return <div key={key} className={styles.label} style={{ background: '#eeeeee33', borderColor: '#eeeeee' }}>
                        <div className={styles.icon}>
                            {cardOpen && <Icon name='Close' color={'#5e656e'} size={20} onClick={() => onRowSelectionChange(p)} />}
                        </div>
                        <div className={styles.property} style={{ paddingRight: cardOpen ? '15px' : '' }}>{property} (NO DATA)</div>
                        <div className={styles.entity}>{entity}</div>
                        <div className={styles.building}>{building}</div>
                    </div>
                }

                return <div key={key} className={styles.label} style={{ background: bgColor, borderColor: color }}
                    onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <div className={styles.property} style={{ paddingRight: cardOpen ? '15px' : '0px' }}>{property}</div>
                    <div className={styles.entity}>{entity}</div>
                    <div className={styles.building}>{building}</div>
                    <div className={styles.icon}>
                        {cardOpen && <Icon name='Close' color={'#5e656e'} size={20} onClick={() => onRowSelectionChange(p)} />}
                    </div>
                </div>
            })}
        </div>
    }

    if (selectedProperties.length === 0) {
        return <div style={{
            height: '80px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
        }}>{cardOpen ? 'Select timeframe and properties to chart' : 'Edit to select timeframe and properties to chart'}</div>
    }

    if (data.length === 0 && !loading) {
        return <div style={{
            height: '100px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
        }}>No data available for the selected property or timeframe.</div>
    }

    if (selectedProperties.length !== 0 && data.length === 0 && loading) {
        return <div style={{
            height: '240px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
        }}></div>
    }

    return <div>
        <HighchartsReact highcharts={Highcharts} options={getChartOptions()} ref={chartRef} />
        {getChartLabels()}
    </div>;
};

export default DataExplorerChart;