import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styles from './DataExplorerCard.module.scss';
import { Button, Switch, Input } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import Constants from '../../../constants';
import { validate } from 'uuid';

import { Icon } from '../../../components/Icon';
import { LogoSpinner } from '../../../components/LogoSpinner';
import { TimeframePicker } from '../../../components/TimeframePicker';
import { PropertyTable } from './PropertyTable';
import { DataExplorerChart } from './DataExplorerChart';

import DataExplorerActions from '../../../actions/dataExplorerActions';

const DataExplorerCard = ({ properties, gatherConfig, config, removeChart, duplicateChart, isShared, moveChartUp, moveChartDown, position, index }) => {

    const [chartData, setChartData] = useState([]);

    const [isEditing, setIsEditing] = useState(false);
    const [cardOpen, setCardOpen] = useState(isNaN(config.chart_id) && isShared === false);
    const [loading, setLoading] = useState(false);
    const [isFirstMount, setIsFirstMount] = useState(true);
    const [movingCharts, setMovingCharts] = useState(false);

    // Timeframe-related state
    const [timeframeMode, setTimeframeMode] = useState(_.get(config, 'timeframes[0].relative') ? 'relative' : 'absolute');
    const [comparison, setComparison] = useState(_.get(config, 'timeframes[1]') ? true : false);
    // Absolute
    const [granularity, setGranularity] = useState(_.get(config, 'timeframes[0].date_type', Constants.BUCKET_WEEK));
    const [tsStart, setTsStart] = useState(_.get(config, 'timeframes[0].absolute.start', moment().startOf('isoWeek').format(Constants.DATA_EXPLORER_DATE_FORMAT)));
    const [tsEnd, setTsEnd] = useState(_.get(config, 'timeframes[0].absolute.end', moment().startOf('isoWeek').add(6, 'days').format(Constants.DATA_EXPLORER_DATE_FORMAT)));
    // Absolute - Comparison
    const [comparisonTsStart, setComparisonTsStart] = useState(_.get(config, 'timeframes[1].absolute.start', moment().startOf('isoWeek').format(Constants.DATA_EXPLORER_DATE_FORMAT)));
    const [comparisonTsEnd, setComparisonTsEnd] = useState(_.get(config, 'timeframes[1].absolute.end', moment().startOf('isoWeek').add(6, 'days').format(Constants.DATA_EXPLORER_DATE_FORMAT)));
    // Relative
    const [relativeAmount, setRelativeAmount] = useState(_.get(config, 'timeframes[0].relative.value', 1));
    const [relativeGranularity, setRelativeGranularity] = useState(_.get(config, 'timeframes[0].date_type', Constants.BUCKET_WEEK));

    // Chart configuration
    const [selectedProperties, setSelectedProperties] = useState(_.get(config, 'properties', []));
    const [bucketSize, setBucketSize] = useState(_.get(config, 'bucket_type', Constants.BUCKET_HOUR));
    const [chartName, setChartName] = useState(_.get(config, 'name', 'New Chart'));
    const [initialChartName, setInitialChartName] = useState('');

    const [refreshData, setRefreshData] = useState(false);

    const createTimeframeArray = useCallback((isFetch = false) => {
        let timeframe = [];
        const modifiedEnd = moment(tsEnd).add(1, 'days').format(Constants.DATA_EXPLORER_DATE_FORMAT);
        const modifiedComparisonEnd = moment(comparisonTsEnd).add(1, 'days').format(Constants.DATA_EXPLORER_DATE_FORMAT);

        if (timeframeMode === 'absolute' && comparison) {
            timeframe = [
                {
                    date_type: granularity,
                    absolute: {
                        start: tsStart,
                        end: isFetch ? modifiedEnd : tsEnd
                    },
                    relative: null
                },
                {
                    date_type: granularity,
                    absolute: {
                        start: comparisonTsStart,
                        end: isFetch ? modifiedComparisonEnd : comparisonTsEnd
                    },
                    relative: null
                }
            ];
        } else if (timeframeMode === 'absolute') {
            timeframe = [{
                date_type: granularity,
                absolute: {
                    start: tsStart,
                    end: isFetch ? modifiedEnd : tsEnd
                },
                relative: null
            }];
        } else if (timeframeMode === 'relative') {
            timeframe = [{
                date_type: relativeGranularity,
                relative: {
                    value: relativeAmount,
                },
                absolute: null
            }];
        }

        return timeframe;
    }, [timeframeMode, comparison, granularity, tsStart, tsEnd, comparisonTsStart, comparisonTsEnd, relativeGranularity, relativeAmount]);
    useEffect(() => {
        const relativeAdjustment = timeframeMode === 'relative' && (relativeGranularity === 'CUSTOM' || relativeGranularity === 'YEAR')
        if (relativeAdjustment) {
            setRelativeGranularity(Constants.BUCKET_WEEK);
        }

        const canFetchData = () => {
            const hasTimeframes = (timeframeMode === 'absolute' && tsStart && tsEnd) || (timeframeMode === 'relative' && relativeAmount && relativeGranularity);
            const hasSelectedRows = selectedProperties.length > 0;
            return bucketSize && hasTimeframes && hasSelectedRows;
        };

        const fetchData = async () => {
            setLoading(true);

            const payload = {
                bucket_type: bucketSize,
                timeframes: createTimeframeArray(true),
                properties: selectedProperties.map(prop => ({
                    entity_type_id: prop.entity.entity_type_id,
                    property_id: prop.property.property_id
                }))
            };

            try {
                const data = await DataExplorerActions.getChartData(payload);
                setChartData(data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            } finally {
                setLoading(false);
            }
        };

        const debouncedFetchData = _.debounce(() => {
            if (canFetchData()) {
                fetchData();
            }
        }, 0);

        if (!relativeAdjustment) debouncedFetchData();

        return () => {
            debouncedFetchData.cancel();
        };
    }, [createTimeframeArray, bucketSize, tsStart, tsEnd, selectedProperties, timeframeMode, relativeAmount, relativeGranularity, granularity, comparison, comparisonTsEnd, comparisonTsStart, refreshData]);

    useEffect(() => {

        const newConfig = {
            name: chartName,
            position: config.position,
            bucket_type: bucketSize,
            timeframes: createTimeframeArray(),
            properties: selectedProperties.map(prop => ({
                entity_type_id: prop.entity.entity_type_id,
                property_id: prop.property.property_id
            }))
        }

        gatherConfig(newConfig);

        // eslint-disable-next-line
    }, [selectedProperties, timeframeMode, relativeAmount, relativeGranularity, tsStart, tsEnd, comparisonTsStart, comparisonTsEnd, comparison, bucketSize, chartName, granularity]);

    // Outline for first mounting
    useEffect(() => {
        const id = config.chart_id;
        if (isFirstMount === true && validate(id)) {
            setIsFirstMount(id);
        } else if (validate(isFirstMount)) {
            setIsFirstMount(false);
        }
        // eslint-disable-next-line
    }, [config.chart_id, index]);

    useEffect(() => {
        if (movingCharts) {
            setTimeout(() => {
                setMovingCharts(false);
            }, 1000);
        }
    }, [movingCharts]);

    const handleKeyPressForInput = (event) => {
        if (event.key === 'Enter') {
            setIsEditing(false);
        } else if (event.key === 'Escape') {
            setChartName(initialChartName);
            setIsEditing(false);
        }
    };

    const handleSelectedRows = (selectedRow) => {
        // Check if an object with the same identifiers already exists in the array
        const existing = selectedProperties.find(obj =>
            obj.entity.entity_type_id === selectedRow.entity.entity_type_id && obj.property.property_id === selectedRow.property.property_id
        );

        if (existing) {
            const updatedProperties = selectedProperties.filter(obj =>
                !(obj.entity.entity_type_id === selectedRow.entity.entity_type_id && obj.property.property_id === selectedRow.property.property_id)
            );
            setSelectedProperties(updatedProperties);
        } else {
            // Check if adding this row exceeds the maximum allowed selections
            if (selectedProperties.length >= 10) {
                console.warn('Maximum of 10 selections allowed.');
                return;
            }

            // Check if adding this row introduces more than 2 different data types
            const dataTypes = new Set(selectedProperties.map(obj => obj.property.data_type));
            if (dataTypes.size >= 2 && !dataTypes.has(selectedRow.property.data_type)) {
                console.warn('No more than 2 different data types allowed.');
                return;
            }

            const updatedProperties = [...selectedProperties, selectedRow]; // Store the entire selectedRow object
            setSelectedProperties(updatedProperties); // Update state to trigger re-render
        }
    }

    const getTimeframePicker = () => {
        const renderGranularityButtons = () => {

            const granularities = [
                { value: Constants.BUCKET_DAY, label: 'Day' },
                { value: Constants.BUCKET_WEEK, label: 'Week' },
                { value: Constants.BUCKET_MONTH, label: 'Month' },
            ]

            return (
                <div className={styles.buttonWrapper}>
                    {granularities.map((g) => (
                        <Button
                            key={g.label}
                            size="small"
                            onClick={() => setRelativeGranularityHandler(g.value)}
                            type={relativeGranularity === g.value ? 'primary' : 'text'}
                            className={classNames({
                                [styles.activeButton]: relativeGranularity === g.value,
                                [styles.inactiveButton]: relativeGranularity !== g.value,
                            })}
                        >
                            {g.label}
                        </Button>
                    ))}
                </div>
            );
        };

        return (
            <div className={styles.timeframePickerSection}>
                <div className={styles.timeframePickerHeader}>
                    <div className={styles.headerTitle}>Date</div>
                    <Switch onChange={toggleTimeframeMode} className={styles.antdSwitch} checkedChildren="Absolute" unCheckedChildren="Relative" checked={timeframeMode === 'absolute'} />
                    {timeframeMode === 'absolute' && <Switch onChange={toggleComparisonMode} className={styles.antdSwitch} checkedChildren="Single" unCheckedChildren="Comparison" checked={!comparison} />}
                </div>
                {timeframeMode === 'absolute' ? (
                    <>
                        <TimeframePicker
                            start={tsStart}
                            end={tsEnd}
                            defaultGranularity={granularity.toLowerCase()}
                            onChange={(start, end, granularity) => setAbsoluteTimeframe(start, end, granularity)}
                            granularities={['custom', 'day', 'week', 'month', 'year']}
                            spacing="10px"
                            backgroundColor='#f3f3f3'
                            buttonColor='#5e656e'
                        />
                        {comparison && <TimeframePicker
                            start={comparisonTsStart}
                            end={comparisonTsEnd}
                            defaultGranularity={granularity.toLowerCase()}
                            onChange={(comparisonStart, comparisonEnd) => setComparisonAbsoluteTimeframe(comparisonStart, comparisonEnd)}
                            granularities={[]}
                            spacing="10px"
                            backgroundColor='#f3f3f3'
                            buttonColor='#5e656e'
                        />}
                    </>
                ) : (
                    <>
                        {renderGranularityButtons()}
                        <Input
                            addonBefore='Last'
                            addonAfter={relativeGranularity.toLowerCase() + 's'}
                            placeholder=""
                            style={{ width: '100%', marginTop: '15px' }}
                            value={relativeAmount}
                            onChange={e => setRelativeTimeframe(e.target.value)}
                            type='number'
                            min={0}
                        />
                    </>
                )}
            </div>
        );
    }

    const adjustBucketSize = (diffInDays, bucket) => {
        if (diffInDays <= 1) {
            if (bucket !== Constants.BUCKET_FIFTEEN_MIN && bucket !== Constants.BUCKET_HOUR) {
                setBucketSize(Constants.BUCKET_HOUR);
            }
        } else if (diffInDays <= Constants.MAX_DAYS_FOR_MIN_BUCKET) {
            if (bucket === Constants.BUCKET_WEEK) {
                setBucketSize(Constants.BUCKET_DAY);
            }
        } else if (diffInDays <= Constants.MAX_DAYS_FOR_HOUR_BUCKET) {
            if (bucket === Constants.BUCKET_FIFTEEN_MIN) {
                setBucketSize(Constants.BUCKET_DAY);
            }
        } else if (diffInDays <= Constants.MAX_DAYS_FOR_DAY_BUCKET) {
            if (bucket === Constants.BUCKET_FIFTEEN_MIN || bucket === Constants.BUCKET_HOUR) {
                setBucketSize(Constants.BUCKET_DAY);
            }
        } else if (diffInDays > Constants.MAX_DAYS_FOR_DAY_BUCKET) {
            if (bucket !== Constants.BUCKET_WEEK) {
                setBucketSize(Constants.BUCKET_WEEK);
            }
        }
    };

    const toggleTimeframeMode = () => {

        if (timeframeMode === 'absolute') {
            const end = moment().format(Constants.DATA_EXPLORER_DATE_FORMAT);
            const start = moment().subtract(relativeAmount, relativeGranularity).format(Constants.DATA_EXPLORER_DATE_FORMAT);
            const diffInDays = moment(end).diff(moment(start), 'days');

            const bucket = bucketSize;
            setTimeframeMode('relative');
            adjustBucketSize(diffInDays, bucket);
        } else if (timeframeMode === 'relative') {
            const start = moment(tsStart).format(Constants.DATA_EXPLORER_DATE_FORMAT);
            const end = moment(tsEnd).format(Constants.DATA_EXPLORER_DATE_FORMAT);
            const diffInDays = moment(start).diff(moment(end), 'days');

            const bucket = bucketSize;
            setTimeframeMode('absolute');
            setComparison(false);
            adjustBucketSize(diffInDays, bucket);
        }

        setTimeframeMode(timeframeMode === 'absolute' ? 'relative' : 'absolute');
    }

    const toggleComparisonMode = () => {
        if (!comparison) {
            setComparisonTsStart(tsStart);
            setComparisonTsEnd(tsEnd);
        }
        setComparison(!comparison);
    }

    const setAbsoluteTimeframe = (start, end, newGranularity) => {
        const tsStart = moment(start).format(Constants.DATA_EXPLORER_DATE_FORMAT);
        const tsEnd = moment(end).format(Constants.DATA_EXPLORER_DATE_FORMAT);
        const diffInDays = moment(tsEnd).diff(moment(tsStart), 'days');
        const compDiffInDays = moment(comparisonTsEnd).diff(moment(comparisonTsStart), 'days');

        setTsStart(tsStart);
        setTsEnd(tsEnd);
        setGranularity(newGranularity.toUpperCase());
        if (newGranularity.toUpperCase() !== granularity.toUpperCase()) {
            setComparisonTsStart(tsStart);
            setComparisonTsEnd(tsEnd);
        }

        // Adjusting the bucket
        const difference = diffInDays > compDiffInDays ? diffInDays : compDiffInDays;
        adjustBucketSize(difference, bucketSize);
    };

    const setComparisonAbsoluteTimeframe = (start, end) => {
        const compTsStart = moment(start).format(Constants.DATA_EXPLORER_DATE_FORMAT);
        const compTsEnd = moment(end).format(Constants.DATA_EXPLORER_DATE_FORMAT);
        const diffInDays = moment(tsEnd).diff(moment(tsStart), 'days');
        const compDiffInDays = moment(compTsEnd).diff(moment(compTsStart), 'days');

        setComparisonTsStart(compTsStart);
        setComparisonTsEnd(compTsEnd);

        // Adjusting the bucket
        const difference = diffInDays > compDiffInDays ? diffInDays : compDiffInDays;
        adjustBucketSize(difference, bucketSize);
    };

    const setRelativeTimeframe = (value) => {
        const end = moment().format(Constants.DATA_EXPLORER_DATE_FORMAT);
        const start = moment().subtract(value, relativeGranularity).format(Constants.DATA_EXPLORER_DATE_FORMAT);
        const diffInDays = moment(end).diff(moment(start), 'days');
        const bucket = bucketSize;

        setRelativeAmount(+value);
        adjustBucketSize(diffInDays, bucket);
    };

    const setRelativeGranularityHandler = (value) => {
        const end = moment().format(Constants.DATA_EXPLORER_DATE_FORMAT);
        const start = moment().subtract(relativeAmount, value).format(Constants.DATA_EXPLORER_DATE_FORMAT);
        const diffInDays = moment(end).diff(moment(start), 'days');
        const bucket = bucketSize;

        setRelativeGranularity(value);
        adjustBucketSize(diffInDays, bucket);
    };

    const getTimeBucketPicker = () => {
        let difference;

        if (timeframeMode === 'absolute') {
            const diff = moment(tsEnd).diff(moment(tsStart), 'days');
            const compDiff = moment(comparisonTsEnd).diff(moment(comparisonTsStart), 'days');

            if (comparison) {
                difference = diff > compDiff ? diff : compDiff;
            } else {
                difference = diff;
            }
        } else if (timeframeMode === 'relative') {
            difference = moment().diff(moment().subtract(relativeAmount, relativeGranularity), 'days');
        }

        const bucketSizes = [
            { value: Constants.BUCKET_FIFTEEN_MIN, label: '15 Min', disabled: difference > Constants.MAX_DAYS_FOR_MIN_BUCKET },
            { value: Constants.BUCKET_HOUR, label: 'Hour', disabled: difference > Constants.MAX_DAYS_FOR_HOUR_BUCKET },
            { value: Constants.BUCKET_DAY, label: 'Day', disabled: difference > Constants.MAX_DAYS_FOR_DAY_BUCKET || difference <= 1 },
            { value: Constants.BUCKET_WEEK, label: 'Week', disabled: difference <= Constants.MAX_DAYS_FOR_MIN_BUCKET },
        ]

        return (
            <div className={styles.bucketPickerSection}>
                <div className={styles.buttonPickerHeader}>
                    <div className={styles.headerTitle}>Time Bucket</div>
                </div>
                <div className={styles.buttonWrapper}>
                    {bucketSizes.map((size) => {
                        return <Button
                            key={size.label}
                            size="small"
                            onClick={() => size.disabled ? undefined : setBucketSize(size.value)}
                            type={bucketSize === size.value ? 'primary' : 'text'}
                            disabled={size.disabled}
                            className={classNames({
                                [styles.activeButton]: bucketSize === size.value,
                                [styles.disabledButton]: size.disabled,
                            })}
                        >
                            {size.label}
                        </Button>
                    })}
                </div>

            </div>
        );
    }

    const getPropertyTable = () => {
        return <div style={{ height: '100%', width: '100%', overflow: 'hidden', minHeight: '200px' }}>
            <PropertyTable
                height='100%'
                selectedRows={selectedProperties}
                onRowSelectionChange={handleSelectedRows}
                clearAllSelections={clearAllSelections}
                data={properties}
                headers={[
                    { label: 'Building', accessor: 'building.name', dropdown: true, filterable: true, width: 20 },
                    { label: 'Type', accessor: 'entity.type', dropdown: true, width: 15 },
                    { label: 'Entity', accessor: 'entity.name', dropdown: true, filterable: true, width: 25 },
                    { label: 'Property', accessor: 'property.name', dropdown: true, filterable: true, width: 25 },
                    { label: 'Unit', accessor: 'property.data_type', dropdown: true, width: 15 },
                ]}

            />

        </div>
    }

    const clearAllSelections = () => {
        setSelectedProperties([]);
        setChartData([]);
    }

    const getTimeframeTitle = () => {
        let bucketSizeLabel = '';
        if (bucketSize === Constants.BUCKET_FIFTEEN_MIN) {
            bucketSizeLabel = '(15 Min intervals)';
        } else if (bucketSize === Constants.BUCKET_HOUR) {
            bucketSizeLabel = '(hourly intervals)';
        } else if (bucketSize === Constants.BUCKET_DAY) {
            bucketSizeLabel = '(daily intervals)';
        } else if (bucketSize === Constants.BUCKET_WEEK) {
            bucketSizeLabel = '(weekly intervals)';
        }

        if (timeframeMode === 'relative') {
            return `Last ${relativeAmount} ${relativeGranularity.toLowerCase()}${relativeAmount > 1 ? 's' : ''} ${bucketSizeLabel}`;
        } else {
            const start = moment(tsStart).format('D MMM YYYY');
            const end = moment(tsEnd).format('D MMM YYYY');
            const comparisonStart = moment(comparisonTsStart).format('D MMM YYYY');
            const comparisonEnd = moment(comparisonTsEnd).format('D MMM YYYY');

            if (comparison) {
                if (start === end && comparisonStart === comparisonEnd) {
                    return `${start} and ${comparisonStart} ${bucketSizeLabel}`;
                } else if (start === end) {
                    return `${start} and ${comparisonStart} - ${comparisonEnd} ${bucketSizeLabel}`;
                } else if (comparisonStart === comparisonEnd) {
                    return `${start} - ${end} and ${comparisonStart} ${bucketSizeLabel}`;
                } else {
                    return `${start} - ${end} and ${comparisonStart} - ${comparisonEnd} ${bucketSizeLabel}`;
                }
            } else {
                if (start === end) return `${start} ${bucketSizeLabel}`;
                return `${start} - ${end} ${bucketSizeLabel}`;
            }
        }
    };

    const getChart = () => {
        return (
            <div style={{ minHeight: '100px', width: '100%' }}>
                <DataExplorerChart
                    data={chartData}
                    loading={loading}
                    selectedProperties={selectedProperties}
                    onRowSelectionChange={handleSelectedRows}
                    cardOpen={cardOpen}
                    timeframeTitle={getTimeframeTitle()}
                    granularity={granularity}
                    refreshData={() => setRefreshData(!refreshData)}
                />
            </div>
        );
    }

    const getCardHeader = () => {
        return (
            <div className={styles.cardHeader}>
                {isEditing ? (
                    <div className={styles.cardTitle}>
                        <Input
                            value={chartName}
                            onChange={e => setChartName(e.target.value)}
                            style={{ width: 'auto' }}
                            onBlur={() => setIsEditing(false)}
                            autoFocus
                            allowClear
                            onKeyDown={handleKeyPressForInput}
                        />
                    </div>
                ) : (
                    cardOpen ? (
                        <div className={styles.cardTitle} onClick={() => { setIsEditing(true); setInitialChartName(chartName); }}>
                            <span>{chartName}</span>
                            <Button
                                icon={<Icon name="Edit" color={'#5e656e'} size={15} />}
                                size="small"
                                style={{ marginLeft: '10px', border: 'none', outline: 'none' }}
                            />
                        </div>
                    ) : (
                        <div className={styles.cardTitle} style={{ background: 'none' }}>
                            <span>{chartName}</span>
                        </div>
                    )
                )}
                {!isShared && <div className={styles.buttonsWrapper}>
                    <Button
                        className="button blue"
                        icon={<Icon name="Copy" color={'#fff'} size={18} />}
                        size="small"
                        style={{ border: 'none', outline: 'none' }}
                        onClick={duplicateChart}
                    >Duplicate</Button>
                    {cardOpen ? (
                        <Button
                            className="button orange"
                            icon={<Icon name="EditOff" color={'#fff'} size={18} />}
                            size="small"
                            style={{ border: 'none', outline: 'none' }}
                            onClick={() => setCardOpen(false)}
                        >Edit Off</Button>
                    ) : (
                        <Button
                            className="button orange"
                            icon={<Icon name="Edit" color={'#fff'} size={18} />}
                            size="small"
                            style={{ border: 'none', outline: 'none' }}
                            onClick={() => setCardOpen(true)}
                        >Edit</Button>
                    )}
                    <Button
                        className="button red"
                        icon={<Icon name="Delete" color={'#fff'} size={18} />}
                        size="small"
                        style={{ border: 'none', outline: 'none' }}
                        onClick={removeChart}
                    >Delete</Button>
                </div>}
            </div>
        );
    }

    const getCardPositionButtons = () => {
        if (isShared) return null;
        if (position === 'only') return null;

        return <div className={styles.moveWrapper}>
            <Button
                className={styles.moveButton}
                icon={<Icon name="ArrowUp" color={'#000'} size={38} />}
                size="small"
                style={{ border: 'none', outline: 'none' }}
                onClick={() => {
                    setMovingCharts(true)
                    moveChartUp()
                }}
                disabled={position === 'first'}
            ></Button>
            <Button
                className={styles.moveButton}
                icon={<Icon name="ArrowDown" color={'#000'} size={38} />}
                size="small"
                style={{ border: 'none', outline: 'none' }}
                onClick={() => {
                    setMovingCharts(true)
                    moveChartDown()
                }}
                disabled={position === 'last'}
            ></Button>
        </div>
    }

    return (
        <div className={classNames(styles.mainWrapper, "card", validate(isFirstMount) ? styles.firstMountOutline : '', movingCharts ? styles.movedOutline : '')} style={{ width: '100%' }}>
            <LogoSpinner loading={loading} />
            {getCardPositionButtons()}
            {getCardHeader()}
            <div className={styles.cardBody} style={{ paddingTop: cardOpen ? '10px' : '0px' }}>
                {cardOpen && <div className={styles.config}>
                    {/* Top configuration section */}
                    <div className='row' style={{ gap: '15px 0' }}>
                        <div className={`${styles.leftConfig} col-12 col-lg-4 mg-b-15`}>
                            <div className='row' style={{ gap: '15px 0' }}>
                                <div className={`${styles.timeframeWrapper} col-12 col-md-6 col-lg-12`}>
                                    {getTimeframePicker()}
                                </div>
                                <div className={`${styles.bucketWrapper} col-12 col-md-6 col-lg-12`}>
                                    {getTimeBucketPicker()}
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.rightConfig} col-12 col-lg-8 mg-b-15`}>
                            {getPropertyTable()}
                        </div>
                    </div>
                </div>}
                <div>
                    {/* Bottom chart section */}
                    <div className='row'>
                        <div className='col-12'>{getChart()}</div>
                    </div>
                </div>
            </div>
        </div >
    );


};

DataExplorerCard.propTypes = {
    properties: PropTypes.arrayOf(PropTypes.object).isRequired,
    gatherConfig: PropTypes.func.isRequired,
    config: PropTypes.shape({
        chart_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        timeframes: PropTypes.arrayOf(PropTypes.shape({
            absolute: PropTypes.shape({
                start: PropTypes.string,
                end: PropTypes.string
            }),
            relative: PropTypes.shape({
                value: PropTypes.number,
                unit: PropTypes.string
            })
        })),
        granularity: PropTypes.string,
        properties: PropTypes.arrayOf(PropTypes.object),
        bucket_type: PropTypes.string,
        name: PropTypes.string,
        position: PropTypes.number
    }).isRequired,
    isShared: PropTypes.bool,
    position: PropTypes.string,
    removeChart: PropTypes.func.isRequired,
    duplicateChart: PropTypes.func.isRequired,
    moveChartUp: PropTypes.func.isRequired,
    moveChartDown: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
};

export default DataExplorerCard;